.table {
  border-spacing: 0;
  font-size: 13px;
  border-radius: 3px;
  border-collapse: collapse;
  color: var(--leagueTable-secondaryFontColor);
}

.row {
  background-color: transparent;
  border-left: 6px solid var(--contentBackground);
  border-top: 1px solid var(--leagueTable-borderColor);
  border-bottom: 1px solid var(--leagueTable-borderColor);
  height: 42px;
}

.winner {
  td:nth-child(1) {
    border-left: 6px solid var(--leagueTable-winnerBorderColor);
  }
}

.playoff {
  td:nth-child(1) {
    border-left: 6px solid var(--leagueTable-playoffBorderColor);
  }
}

.loser {
  td:nth-child(1) {
    border-left: 6px solid var(--leagueTable-loserBorderColor);
  }
}

.highlightedRow {
  background-color: var(--leagueTable-highlightedRowColor);
}

.header {
  > tr {
    height: 42px;
  }
}

.headerCell {
  font-weight: normal;
  &:nth-child(1) {
    width: 5%;
    text-align: left;
    padding-left: 16px;
  }
  &:nth-child(2) {
    width: 5%;
    text-align: center;
  }
  &:nth-child(3) {
    width: 40%;
    text-align: left;
  }
}

.dataCell {
  text-align: center;
}

.bold {
  font-weight: bold;
  color: var(--leagueTable-primaryFontColor);
}

.teamDetails {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  max-width: 90px;

  > :first-child {
    margin-right: 9px !important;
  }
}

.footerRow {
  font-size: 0.75rem;
  padding: 16px;

  > :first-child {
    padding: 16px;
  }
}
